// ScrollSpy
var scrollSpy = new bootstrap.ScrollSpy(document.body)

// Swiper
var swiper = new Swiper('.swiper', {
    // clickable: true,
    direction: 'vertical',
    slidesPerView: 3,
    initialSlide: 1,
    centeredSlides: true,
    mousewheel: true,
    slideToClickedSlide: true,
    on: {
        slideChange: function (s) {
            var id = s.slides[s.realIndex].dataset.id
            updateSlideContent(id)
        }
    }
})

var swiperButton = $('.js-slider-button')
swiperButton.click(function(e){
    e.preventDefault()
    var id = $(this).parent().attr('data-id')
    updateSlideContent(id)
    updatePagePosition()
})


var closeDescription = $('.js-close-description')
closeDescription.click(function(e){
    e.preventDefault()
    $('.content').addClass('hide')
})

function updateSlideContent(id) {
    $('.content').removeClass('hide')
    $('.content > div').addClass('hide')
    $(`#content-${id}`).removeClass('hide')
} 

function updatePagePosition() {
    var scrollTop = $('.swiper').offset().top - 130
    window.scrollTo(0, scrollTop)
} 

$(document).ready(function(){
    window.scrollTo(0, 0)
})